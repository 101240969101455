var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-page"},[_c('carousel',{attrs:{"carouselImg":_vm.carouselImg,"height":750,"minWidth":1200}}),_c('group-member'),_c('div',{staticClass:"IntroductionBanner"},[_c('div',{staticClass:"warpper"},[_vm._m(0),(_vm.videoData)?_c('div',{staticClass:"profile-section"},[_vm._m(1),_c('div',{staticClass:"profile-detail",on:{"click":function () { return _vm.goThere('groupIntroduction'); }}},[_c('h6',{staticClass:"company-name"},[_vm._v(_vm._s(_vm.videoData.name))]),_c('div',{staticClass:"line"}),_c('span',{staticClass:"biref"},[_vm._v(_vm._s(_vm.videoData.brief))])])]):_vm._e()])]),_c('div',{staticClass:"newsBanner"},[_c('div',{staticClass:"warpper"},[_vm._m(2),_c('div',{staticClass:"news-section"},[_c('div',{staticClass:"news-list"},[_c('div',{staticClass:"list-box"},_vm._l((_vm.news),function(item,index){return _c('div',{key:index,class:[
                  'list-item',
                  _vm.currentNews && _vm.currentNews.id == item.id
                    ? 'list-item-actived'
                    : '' ],on:{"mouseenter":function($event){return _vm.cutNews(item)},"click":function($event){return _vm.checkNews(item)}}},[_c('p',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"item-time"},[_vm._v(_vm._s(item.createDate.split(" ")[0]))])])}),0),(_vm.currentNews)?_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":_vm.currentNews.servicePath + _vm.currentNews.cover,"alt":""}})]):_vm._e()]),_c('a',{staticClass:"more",on:{"click":function () { return _vm.goThere('companyNews'); }}},[_vm._v("more")])])])]),_c('div',{staticClass:"memberBanner"},[_c('div',{staticClass:"warpper"},[_vm._m(3),_c('div',{staticClass:"member-section"},[_c('ul',{staticStyle:{"overflow":"hidden"}},_vm._l((_vm.memberData),function(item,index){return (item.name != '大数据')?_c('li',{key:index,class:_vm.current == index ? 'active' : '',on:{"mouseenter":function($event){return _vm.enter(index)}}},[_c('div',{staticClass:"title-img"},[_c('img',{attrs:{"src":item.servicePath + item.icon,"alt":""}}),_c('div',{staticClass:"shade"},[_c('h6',[_c('span',[_vm._v("+")]),_vm._v(_vm._s(item.name))])])]),_c('div',{staticClass:"member-info"},[_c('div',{staticClass:"member-info-detail"},[_c('h6',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(_vm._s(item.tips.split("/")[0]))]),_c('p',[_vm._v(" "+_vm._s(item.tips.split("/")[1])+" ")]),_c('a',{staticClass:"turn",on:{"click":function($event){return _vm.goProduct(item)}}})])])]):_vm._e()}),0)])])]),_c('div',{staticClass:"honorBanner"},[_c('div',{staticClass:"warpper"},[_vm._m(4),_c('div',{staticClass:"honor-section"},[_c('div',{staticClass:"run-one"},[(_vm.currentHonorImg)?_c('div',{staticClass:"run-one-child"},[_c('ul',{ref:"imgBox",style:({
                  left: _vm.runImgOneUl + 'px',
                  width: _vm.runImgOneWidth + 'px',
                })},[_c('li',{on:{"click":function () { return _vm.checkHonorDetail(_vm.honorImg[_vm.length - 2], _vm.honorImg); }}},[_c('img',{staticClass:"small",attrs:{"src":_vm.honorImg[_vm.length - 2].servicePath +
                      _vm.honorImg[_vm.length - 2].cover}})]),_c('li',{on:{"click":function () { return _vm.checkHonorDetail(_vm.honorImg[_vm.length - 1], _vm.honorImg); }}},[_c('img',{staticClass:"small",attrs:{"src":_vm.honorImg[_vm.length - 1].servicePath +
                      _vm.honorImg[_vm.length - 1].cover}})]),_vm._l((_vm.honorImg),function(item,index){return _c('li',{key:index,ref:"item",refInFor:true,on:{"click":function () { return _vm.checkHonorDetail(item, _vm.honorImg); }}},[_c('img',{class:[_vm.currentHonorImg.id == item.id ? 'big' : 'small'],attrs:{"src":item.servicePath + item.cover}})])}),_c('li',{on:{"click":function () { return _vm.checkHonorDetail(_vm.honorImg[0], _vm.honorImg); }}},[_c('img',{staticClass:"small",attrs:{"src":_vm.honorImg[0].servicePath + _vm.honorImg[0].cover}})]),_c('li',{on:{"click":function () { return _vm.checkHonorDetail(_vm.honorImg[1], _vm.honorImg); }}},[_c('img',{staticClass:"small",attrs:{"src":_vm.honorImg[1].servicePath + _vm.honorImg[1].cover}})])],2)]):_vm._e()]),_c('a',{staticClass:"more"},[_vm._v("more")])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index-title"},[_c('h6',[_vm._v(" Company "),_c('span',[_vm._v("profile")])]),_c('p',[_c('span',[_vm._v("—— 公司简介 ——")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile-video"},[_c('img',{staticStyle:{"width":"100%","height":"271.69px"},attrs:{"src":"/imgs/v.png","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index-title"},[_c('h6',[_vm._v(" Company "),_c('span',[_vm._v("NEWS")])]),_c('p',[_c('span',[_vm._v("—— 公司新闻 ——")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index-title"},[_c('h6',[_vm._v(" GROUP "),_c('span',[_vm._v("member")])]),_c('p',[_c('span',[_vm._v("—— 品牌产品 ——")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index-title"},[_c('h6',[_vm._v(" HONOR "),_c('span',[_vm._v("QUALIFICATIONS")])]),_c('p',[_c('span',[_vm._v("—— 荣誉资质 ——")])])])}]

export { render, staticRenderFns }