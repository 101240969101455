var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner",style:({
    height: _vm.height ? (_vm.height + "px") : '500px',
    minWidth: _vm.minWidth ? (_vm.minWidth + "px") : '100%',
  })},[_c('ul',{staticClass:"slides"},_vm._l((_vm.carouselImg),function(item,index){return _c('li',{key:index,class:[
        _vm.flag
          ? _vm.carouselIndex === index
            ? 'into'
            : _vm.carouselIndex === 0
            ? _vm.carouselImg.length - 1 === index
              ? 'leave'
              : 'hide'
            : _vm.carouselIndex - 1 === index
            ? 'leave'
            : 'hide'
          : _vm.carouselIndex === index
          ? 'show'
          : 'hide' ],style:({
        background: ("url(" + (item.icon) + ") center center no-repeat"),
        backgroundSize: ("100% " + _vm.height + "px"),
        minWidth: _vm.minWidth ? (_vm.minWidth + "px") : '100%',
        width: '100%',
        zIndex: _vm.carouselIndex === index ? 2 : 1,
      })})}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }