<template>
  <div
    class="banner"
    :style="{
      height: height ? `${height}px` : '500px',
      minWidth: minWidth ? `${minWidth}px` : '100%',
    }"
  >
    <ul class="slides">
      <li
        v-for="(item, index) in carouselImg"
        :key="index"
        :class="[
          flag
            ? carouselIndex === index
              ? 'into'
              : carouselIndex === 0
              ? carouselImg.length - 1 === index
                ? 'leave'
                : 'hide'
              : carouselIndex - 1 === index
              ? 'leave'
              : 'hide'
            : carouselIndex === index
            ? 'show'
            : 'hide',
        ]"
        :style="{
          background: `url(${item.icon}) center center no-repeat`,
          backgroundSize: `100% ${height}px`,
          minWidth: minWidth ? `${minWidth}px` : '100%',
          width: '100%',
          zIndex: carouselIndex === index ? 2 : 1,
        }"
      ></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    //轮播图片数组
    carouselImg: {
      type: Array,
      default() {
        return [];
      },
    },
    //轮播组件高度
    height: {
      type: Number,
      default: 0,
    },
    //轮播组件最小宽度
    minWidth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      carouselIndex: 0,
      count: 0,
      flag: false,
      timer: null,
    };
  },
  methods: {
    carouse() {
      this.timer = setInterval(() => {
        this.count++;
        if (this.count === this.carouselImg.length) {
          this.count = 0;
        }
        this.carouselIndex = this.count;
        !this.flag && (this.flag = true)
      }, 5000);
    },
  },
  mounted() {
    this.carouse();
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.banner {
  margin: 0 auto;

  .slides {
    height: 100%;
    position: relative;

    li {
      height: 100%;
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 0;
    }
    .show {
      opacity: 1;
    }
    .hide {
      opacity: 0;
    }
    .into {
      animation: in 1s;
    }
    .leave {
      animation: out 1s;
    }
    @keyframes in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes out {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
}
</style>